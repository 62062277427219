import css from './index.module.scss';
import SupportLeftMenu from '@/components/SupportCommon/SupportLeftMenu';
import { useGlobal } from '@/utils/useGlobal';
import IotComp from './IotComp';
import DirectConnection from './DirectConnection';
import Bluetooth from './Bluetooth';

export default function QuickGuide() {
    const { useLang, useState, useEffect, useRouter } = useGlobal();
    const { t } = useLang('app');
    const { locale } = useRouter();
    const [treeSelect, setTreeSelect] = useState({});
    const [childSelect, setChildSelect] = useState({});
    const isShowEn = ['us', 'au', 'eu', 'ca', 'uk', 'za', 'cis', 'br'].includes(locale);

    const categoryTree = [
        {
            title: t('quickGuideChild.tree1'),
            value: '0',
            treeTitle: t('quickGuideChild.tree1-title'),
        },
        { title: t('quickGuideChild.tree2'), value: '1' },
        {
            title: t('quickGuideChild.tree3'),
            treeTitle: t('quickGuideChild.content-title'),
            value: '2',
            children: [
                {
                    title: t('quickGuideChild.tree3-iot'),
                    value: '3',
                    desc1: t('quickGuideChild.iot-model1'),
                    desc2: t('quickGuideChild.iot-model2'),
                    step: [
                        {
                            title: t('quickGuideChild.iot-step1'),
                            img: isShowEn ? '/img/appDownload/pc/iot/1.png' : `/img/appDownload/pc/iot/1-${locale}.png`,
                        },
                        {
                            title: t('quickGuideChild.iot-step2'),
                            img: isShowEn ? '/img/appDownload/pc/iot/2.png' : `/img/appDownload/pc/iot/2-${locale}.png`,
                        },
                        {
                            title: t('quickGuideChild.iot-step3'),
                            img: isShowEn ? '/img/appDownload/pc/iot/3.png' : `/img/appDownload/pc/iot/3-${locale}.png`,
                        },
                    ],
                    note: t('quickGuideChild.note1'),
                },
                {
                    title: t('quickGuideChild.tree3-direct'),
                    value: '4',
                    desc1: t('quickGuideChild.direct-model1'),
                    desc2: t('quickGuideChild.direct-model2'),
                    step: [
                        {
                            title: t('quickGuideChild.direct-step1'),
                            img: isShowEn ? '/img/appDownload/pc/connection/1.png' : `/img/appDownload/pc/connection/1-${locale}.png`,
                        },
                        {
                            title: t('quickGuideChild.direct-step2'),
                            img: isShowEn ? '/img/appDownload/pc/connection/2.png' : `/img/appDownload/pc/connection/2-${locale}.png`,
                        },
                        {
                            title: t('quickGuideChild.direct-step3'),
                            img: isShowEn ? '/img/appDownload/pc/connection/3.png' : `/img/appDownload/pc/connection/3-${locale}.png`,
                        },
                        {
                            title: t('quickGuideChild.direct-step4'),
                            img: isShowEn ? '/img/appDownload/pc/connection/4.png' : `/img/appDownload/pc/connection/4-${locale}.png`,
                        },
                    ],
                    note: t('quickGuideChild.note2'),
                },
                {
                    title: t('quickGuideChild.tree3-bluetooth'),
                    value: '5',
                    desc1: t('quickGuideChild.bluetooth-model1'),
                    desc2: t('quickGuideChild.bluetooth-model2'),
                    step: [
                        {
                            title: t('quickGuideChild.bluetooth-step1'),
                            img: isShowEn ? '/img/appDownload/pc/bluetooth/1.png' : `/img/appDownload/pc/bluetooth/1-${locale}.png`,
                        },
                        {
                            title: t('quickGuideChild.bluetooth-step2'),
                            img: isShowEn ? '/img/appDownload/pc/bluetooth/2.png' : `/img/appDownload/pc/bluetooth/2-${locale}.png`,
                        },
                        {
                            title: t('quickGuideChild.bluetooth-step3'),
                            img: isShowEn ? '/img/appDownload/pc/bluetooth/3.png' : `/img/appDownload/pc/bluetooth/3-${locale}.png`,
                        },
                    ],
                    note: t('quickGuideChild.note3'),
                },
            ],
        },
    ];

    const onSelect = (value: string) => {
        if (Number(value) < 2) {
            setTreeSelect(categoryTree[Number(value)]);
        }
        if (Number(value) > 2) {
            setTreeSelect(categoryTree[2]);
            const child = categoryTree[2]?.children?.find((item: any) => item.value == value);
            setChildSelect(child);
        }
    };

    useEffect(() => {
        onSelect(0);
    }, []);

    return (
        <div className={css.quickGuide}>
            <div className="quick-guide">
                <SupportLeftMenu categoryTree={categoryTree} showSearch={false} onSelect={onSelect} defaultSelectId={['0']} />
                <div className="content-box">
                    <div className="title" style={{ textAlign: treeSelect.value === '0' ? 'center' : 'left' }}>
                        {treeSelect.treeTitle || treeSelect.title}
                    </div>
                    <div className="child-box">
                        {treeSelect.value === '0' && <IotComp />}
                        {treeSelect.value === '1' && <DirectConnection />}
                        {treeSelect.value === '2' && <Bluetooth data={childSelect} />}
                    </div>
                </div>
            </div>
        </div>
    );
}
