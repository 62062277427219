import Image from '@/components/UI/Image';
import EFDownloadAppH5Svg from '@/components/UI/svg/Icons/EFDownloadAppH5Svg';
import { URL } from '@/constants';
import { useGlobal } from '@/utils/useGlobal';
import * as deviceDetect from 'react-device-detect';
import css from './index.module.scss';
export default function DownloadSection({ clickHere, iosAppVersionInfo }: any) {
    const { useLang, Link, useRouter, useState, useEffect } = useGlobal();
    const { t } = useLang('app');
    const { t: s } = useLang('shared');
    const { locale } = useRouter();
    const { APP_ANDROID, APP_IOS, APK_OVERSEAS, APK_CN, APP_IOS_CN, APP_IOS_RU } = URL;
    const androidAppVersionInfo = { appVersion: '2.6.2', minOSVersion: '5.0' };
    const [appStoreSrc, setAppStoreSrc] = useState('/img/download/apple-store.png');
    const [googlePaySrc, setGooglePaySrc] = useState('/img/download/google-play.png');
    const [bottomBg, setBottomBg] = useState('/img/appDownload/h5/05-bg.png');
    const [isIOS, setIsIOS] = useState(false);

    const getAppDataLayer = (type: string) => ({
        event: 'uaEvent',
        event_name: 'ecoflow_app',
        button_name: type,
    });

    useEffect(() => {
        setIsIOS(deviceDetect.isIOS);
        if (['us', 'au', 'eu', 'ca', 'uk', 'za', 'cis', 'br'].includes(locale)) {
            setAppStoreSrc('/img/download/apple-store.png');
            setGooglePaySrc('/img/download/google-play.png');
            setBottomBg(['us'].includes(locale) ? '/img/appDownload/h5/05-bg-diff.jpg' : '/img/appDownload/h5/05-bg.png');
        } else {
            setAppStoreSrc(`/img/appDownload/pc/apple-store-${locale}.png`);
            setGooglePaySrc(`/img/appDownload/pc/google-play-${locale}.png`);
            setBottomBg(`/img/appDownload/h5/05-bg-${locale}.png`);
        }
    }, []);

    return (
        <div className={css.downloadSection}>
            <div className="download-box">
                <div className="title-box">
                    <div className="logo">
                        <Image src="/img/appDownload/h5/05-logo.png" layout="fill" className="fullImgs" />
                    </div>
                    <div className="logo-text">{t('downloadSection.ecoflow-app')}</div>
                </div>
                <div className="desc">{t('downloadSection.app-desc')}</div>
                {isIOS ? (
                    <Link
                        href={locale === 'cn' ? APP_IOS_CN : locale === 'cis' ? APP_IOS_RU : APP_IOS}
                        aria-label={t('downloadSection.apple-store')}
                        dataLayer={getAppDataLayer('App Store')}
                    >
                        <div className="store-img">
                            <Image src={appStoreSrc} layout="fill" alt={s('support.download.app_store')} />
                        </div>
                    </Link>
                ) : (
                    <div className="google-box">
                        <Link href={APP_ANDROID} ariaLabel={s('support.download.google_play')} dataLayer={getAppDataLayer('Google Play')}>
                            <div className="store-img">
                                <Image src={googlePaySrc} layout="fill" alt={t('support.download.google_play')} />
                            </div>
                        </Link>
                    </div>
                )}
                <div className={`store-desc ${!isIOS ? 'google-text' : ''}`}>
                    {isIOS
                        ? `${t('downloadSection.apple-store')}${iosAppVersionInfo.minOSVersion || '11.0'} ${t('downloadSection.or-above')}`
                        : `${t('downloadSection.google-play')}${androidAppVersionInfo.minOSVersion} ${t('downloadSection.or-above')}`}
                </div>
                {!isIOS && (
                    <Link
                        href={locale === 'cn' ? APK_CN : APK_OVERSEAS}
                        ariaLabel={s('site_support_download_apk')}
                        selfWin
                        dataLayer={getAppDataLayer('Download the EcoFlow App apk file')}
                    >
                        <div className="download-link">
                            <EFDownloadAppH5Svg width="0.14rem" height="0.14rem" className="download-svg" />
                            <div className="link-text">{t('downloadSection.link-text')}</div>
                        </div>
                    </Link>
                )}
                <div className="bottom-img">
                    <Image src={bottomBg} layout="fill" className="fullImgs" />
                </div>
            </div>
            <div className="click-here">
                {t.rich('downloadSection.click-here', {
                    line1: (text: any) => (
                        <span className="color-ms1" onClick={clickHere}>
                            {text}
                        </span>
                    ),
                })}
            </div>
        </div>
    );
}
