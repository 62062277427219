import css from './index.module.scss';
import { useGlobal } from '@/utils/useGlobal';
import {
    EFTabIotNotSelectH5,
    EFTabIotSelectH5,
    EFTabApNotSelectH5,
    EFTabApSelectH5,
    EFTabBluetoothNotSelectH5,
    EFTabBluetoothSelectH5,
} from '@/components/UI/svg/appDownload';
import EFVideo from '@/components/UI/EFVideo';

export default function IotComp() {
    const { useLang, useState, useEffect } = useGlobal();
    const { t } = useLang('app');
    const [activeTab, setActiveTab] = useState(0);
    const [selectTab, setSelectTab] = useState({});

    const data = [
        {
            title: t('quickGuideChild.tab1'),
            desc: t('quickGuideChild.iot-desc'),
            video: ['/video/app/h5/iot-video.mp4', '/video/app/h5/iot-video.webm'],
            svg: <EFTabIotNotSelectH5 width="0.2rem" height="0.2rem" />,
            selectSvg: <EFTabIotSelectH5 width="0.2rem" height="0.2rem" />,
        },
        {
            title: t('quickGuideChild.tab3'),
            desc: t('quickGuideChild.direct-desc'),
            video: ['/video/app/h5/wifi-video.mp4', '/video/app/h5/wifi-video.webm'],
            svg: <EFTabApNotSelectH5 width="0.2rem" height="0.2rem" />,
            selectSvg: <EFTabApSelectH5 width="0.2rem" height="0.2rem" />,
        },
        {
            title: t('quickGuideChild.tab2'),
            desc: t('quickGuideChild.bluetooth-desc'),
            svg: <EFTabBluetoothNotSelectH5 width="0.2rem" height="0.2rem" />,
            video: ['/video/app/h5/bluetooth-video.mp4', '/video/app/h5/bluetooth-video.webm'],
            selectSvg: <EFTabBluetoothSelectH5 width="0.2rem" height="0.2rem" />,
        },
    ];

    const clickTab = (index: number) => {
        setActiveTab(index);
        setSelectTab(data[index]);
    };

    useEffect(() => {
        setSelectTab(data[activeTab]);
    }, []);

    return (
        <div className={css.iotComp}>
            <div className="iot-comp">
                <div className="tab-box">
                    {data.map((item: any, index) => (
                        <div className={`item-box ${activeTab === index ? 'active' : ''}`} key={index} onClick={() => clickTab(index)}>
                            {activeTab === index ? item.selectSvg : item.svg}
                            <span className={`tab-item tab-item${index + 1}`}>{item.title}</span>
                        </div>
                    ))}
                </div>
                <div className="box">
                    <div className="box-title">{t('quickGuideChild.tree1-title')}</div>
                    <div className="desc">{selectTab.desc}</div>
                    <div className="video-box">
                        <EFVideo key={selectTab.title} sources={selectTab.video} className="video" />
                    </div>
                </div>
            </div>
        </div>
    );
}
